import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import CoreContainer from "../core-container";
import LottieImage from "../lottie-image";

const Section = ({ title, body, image, lottieFile, assetDimension, className = "" }) => {
  return (
    <article className={cx("grid self-center text-center align-middle", className)}>
      <LottieImage
        image={image}
        lottieFile={lottieFile}
        width={assetDimension?.width}
        height={assetDimension?.height}
        className="flex justify-center"
      />
      {title && <p className="text-lg font-medium leading-none text-accent-700 md:text-2xl">{title}</p>}
      <p className="px-6 pt-1 text-xs font-light md:px-20 md:text-sm">{body}</p>
    </article>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  Image: PropTypes.shape({}),
  lottieFile: PropTypes.shape({}),
  assetDimension: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
};

const Numbers = ({ data = {} }) => {
  const { theme, moduleWidth, numberList } = data;
  const isFullWidth = moduleWidth?.size === "fullWidth";
  const isThreeColumn = numberList?.length === 3;
  return (
    <CoreContainer isFullWidth={isFullWidth} theme={theme?.value}>
      <section className="num px-2 py-10 pt-10 md:py-14">
        <div
          className={cx("text-center align-middle md:gap-4", {
            ["flex flex-wrap justify-center gap-x-2 gap-y-6 md:!grid md:grid-cols-3 md:justify-between"]: isThreeColumn,
            ["grid grid-cols-2 justify-center gap-6 sm:justify-between md:grid-cols-4"]: !isThreeColumn,
          })}
        >
          {numberList?.map((section, index) => (
            <Section
              key={index}
              className={index === 0 && isThreeColumn ? "w-full md:w-auto" : ""}
              body={section.body}
              title={section.title}
              image={section.Image}
              lottieFile={section.lottieFile}
              assetDimension={section.assetDimension}
            />
          ))}
        </div>
      </section>
    </CoreContainer>
  );
};

Numbers.propTypes = {
  data: PropTypes.shape({
    numberList: PropTypes.arrayOf(PropTypes.shape(Section.propTypes)),
    moduleWidth: PropTypes.shape({
      size: PropTypes.string,
    }),
    theme: PropTypes.shape({
      value: PropTypes.oneOf(["light", "soft", "dark", "new", "softgradient"]),
    }),
  }),
};

export default Numbers;
